/**
 * 微信js-sdk
 * 参考文档：https://mp.weixin.qq.com/wiki?t=resource/res_main&id=mp1421141115
 */
import wx from 'weixin-js-sdk'

const wxApi = {
    /**
     * [wxRegister 微信Api初始化]
     * @param  {Function} callback [ready回调函数]
     */
    wxRegister(data, option) { //data是微信配置信息，option是分享的配置内容
        console.info("---->app_id = " + data.app_id);
        console.info("---->timestamp = " + data.timestamp);
        console.info("---->nonce_str = " + data.nonce_str);
        console.info("---->signature = " + data.signature);
        console.info("-------------------------");
        /* console.info("---->title = " + option.title);
       console.info("---->link = " + option.link);
       console.info("---->imgUrl = " + option.imgUrl);
       console.info("---->desc = " + option.desc);*/

        wx.config({
            debug: false, // 开启调试模式
            appId: data.app_id, // 必填，公众号的唯一标识
            timestamp: data.timestamp, // 必填，生成签名的时间戳
            nonceStr: data.nonce_str, // 必填，生成签名的随机串
            signature: data.signature, // 必填，签名，见附录1
            jsApiList: [
                'checkJsApi',
                'onMenuShareTimeline',   //分享给好友
                'onMenuShareAppMessage',  //分享朋友圈
                'updateAppMessageShareData', //分享给好友 1.4 新版本
                'updateTimelineShareData',   //分享到朋友圈 1.4 新版本
                'onMenuShareQQ',   //分享到QQ
                'onMenuShareQZone',  //分享到QQ空间
                'onMenuShareWeibo'   //分享到腾讯微博
            ] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
        })
        wx.ready(function () {
            //分享朋友圈
            wx.onMenuShareTimeline({
                title: option.title, // 分享标题
                link: option.link, // 分享链接
                imgUrl: option.imgUrl, // 分享图标
                success() {
                    // 用户成功分享后执行的回调函数
                    option.success()
                },
                cancel() {
                    // 用户取消分享后执行的回调函数
                    option.error()
                }
            });
            //分享朋友圈 新版本
            wx.updateTimelineShareData({
                title: option.title, // 分享标题
                link: option.link, // 分享链接
                imgUrl: option.imgUrl, // 分享图标
                success() {
                    // 用户成功分享后执行的回调函数
                    option.success()
                },
                cancel() {
                    // 用户取消分享后执行的回调函数
                    option.error()
                }
            });
            //分享好友
            wx.onMenuShareAppMessage({
                title: option.title, // 分享标题
                desc: option.desc, // 分享描述
                link: option.link, // 分享链接
                imgUrl: option.imgUrl, // 分享图标
                success() {
                    // 用户成功分享后执行的回调函数
                    option.success()
                },
                cancel() {
                    // 用户取消分享后执行的回调函数
                    option.error()
                }
            });
            //分享好友 新版本
            wx.updateAppMessageShareData({
                title: option.title, // 分享标题
                desc: option.desc, // 分享描述
                link: option.link, // 分享链接
                imgUrl: option.imgUrl, // 分享图标
                success() {
                    // 用户成功分享后执行的回调函数
                    option.success()
                },
                cancel() {
                    // 用户取消分享后执行的回调函数
                    option.error()
                }
            });
            //分享到QQ
            wx.onMenuShareQQ({
                title: option.title, // 分享标题
                desc: option.desc, // 分享描述
                link: option.link, // 分享链接
                imgUrl: option.imgUrl, // 分享图标
                success() {
                    // 用户成功分享后执行的回调函数
                    option.success()
                },
                cancel() {
                    // 用户取消分享后执行的回调函数
                    option.error()
                }
            });
            //分享到腾讯微博
            wx.onMenuShareWeibo({
                title: option.title, // 分享标题
                desc: option.desc, // 分享描述
                link: option.link, // 分享链接
                imgUrl: option.imgUrl, // 分享图标
                success() {
                    // 用户成功分享后执行的回调函数
                    option.success()
                },
                cancel() {
                    // 用户取消分享后执行的回调函数
                    option.error()
                }
            });
            //分享到QQ空间
            wx.onMenuShareQZone({
                title: option.title, // 分享标题
                desc: option.desc, // 分享描述
                link: option.link, // 分享链接
                imgUrl: option.imgUrl, // 分享图标
                success() {
                    // 用户成功分享后执行的回调函数
                    option.success()
                },
                cancel() {
                    // 用户取消分享后执行的回调函数
                    option.error()
                }
            });
        })
    }
}
export default wxApi
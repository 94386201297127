<template>
  <!-- 分享页面 -->
  <div id="index" :dir="language === 'uy' ? 'rtl' : ''" class="my-font-text">
    <div class="plate">
      <el-image
          style="width: 100%"
          :src="detailData.cover_img"
          fit="contain"></el-image>
      <div class="p15"></div>
      <div>
        <!-- 标题 -->
        <div class="title">{{ detailData.title }}</div>

        <!-- 电台音频 -->
        <div class="audio" v-if="this.detailData.audio_url" @click="clickAudio()">
          <div>
            <el-image v-if="this.isAudioPause"
                      style="width: 18px; height: 18px;"
                      :src="require('@/assets/png/icon_article_pause.png')"
                      fit="contain"></el-image>
            <el-image v-else
                      style="width: 18px; height: 18px;"
                      :src="require('@/assets/png/icon_article_playing.png')"
                      fit="contain"></el-image>
          </div>

          <div style="color: #cb7a35; font-size: 15px; margin-left: 5px; margin-right: 5px">
            {{ $t("message.qabillarAudio") }}
          </div>

          <div>
            <el-image
                style="width: 15px; height: 15px;"
                :src="require('@/assets/png/icon_wavy.png')"
                fit="contain"></el-image>
            <el-image
                style="width: 15px; height: 15px;"
                :src="require('@/assets/png/icon_wavy.png')"
                fit="contain"></el-image>
            <el-image
                style="width: 15px; height: 15px;"
                :src="require('@/assets/png/icon_wavy.png')"
                fit="contain"></el-image>
            <el-image
                style="width: 15px; height: 15px;"
                :src="require('@/assets/png/icon_wavy.png')"
                fit="contain"></el-image>
            <el-image
                style="width: 15px; height: 15px;"
                :src="require('@/assets/png/icon_wavy.png')"
                fit="contain"></el-image>
            <el-image
                style="width: 15px; height: 15px;"
                :src="require('@/assets/png/icon_wavy.png')"
                fit="contain"></el-image>
            <el-image
                style="width: 15px; height: 15px;"
                :src="require('@/assets/png/icon_wavy.png')"
                fit="contain"></el-image>
          </div>
        </div>

        <div class="p20"></div>
        <!-- 副标题 -->
        <div v-if="detailData.introduction" class="detailData-introduction">
          {{ detailData.introduction }}
        </div>
        <!-- 分割线 -->
        <el-divider></el-divider>
      </div>

      <div class="value">
        <!-- 自定义网页内容 -->
        <dl v-html="detailData.content"></dl>
      </div>

      <div class="bottom">
        <!-- 收费提示 -->
        <div class="charging-tips" v-if="detailData.is_fee === '1'">
          <div>
            <img src="../../assets/png/vip_lock.png" height="30" width="30"/>
          </div>
          <div style="color: #606266">
            {{ $t("message.chargingTips") }}
          </div>
          <div class="bottom-fabulous-row">
            <div class="charging-button" @click="openApp()">
              {{ $t("message.purchase") }}(￥{{ detailData.price }})
            </div>
          </div>
        </div>
        <div class="p15"></div>

        <!-- 点赞按钮 -->
        <div class="bottom-fabulous-row">
          <div class="bottom-fabulous-button" @click="openApp()">
            <div v-if="detailData.is_like === '0'">
              <img src="../../assets/png/fabulous.png" height="30" width="30"/>
            </div>
            <div v-else>
              <img src="../../assets/png/like.png" height="30" width="30"/>
            </div>
            <div
                style="color: #f16217; width: 50px; height: 18px; overflow: hidden; text-overflow: ellipsis; line-height: 18px;">
              {{ detailData.like_num }}
            </div>
          </div>
        </div>
        <div class="p15"></div>

        <div class="p10"></div>
        <div class="bottom-list">
          <el-button
              style="border: 0; background-color: #f2f2f2; padding: 5px 10px"
              v-for="topic in detailData.topic_list"
              :key="topic.title"
              :round="true"
              size="mini"
              @click="clickToLearnMoreMethod(topic.id)">
            {{ topic.title }}
          </el-button>
        </div>
      </div>
      <div class="p15"></div>
    </div>
    <div class="p15"></div>

    <div class="plate">
      <div class="title">
        {{ $t("message.recommendation") }}
      </div>
      <div class="recommend" :key="recommend.id"
           v-for="recommend in recommendList"
           @click="clickToLearnMoreMethod(recommend.id)">
        <div>
          <el-image
              style="width: 90px; height: 60px; border-radius: 6px"
              :src="recommend.cover_img"
              fit="fill"></el-image>
        </div>
        <div class="recommend-r">
          <div class="title">{{ recommend.title }}</div>
          <div class="p8"></div>
          <div style="color: #606266">{{ recommend.introduction }}</div>
        </div>
      </div>
    </div>
    <div class="p20"></div>
    <div class="p60" v-if="isShowOpenAppButton"></div>

    <!-- 底部栏 -->
    <div class="bottom-app" v-if="isShowOpenAppButton">
      <div>
        <el-image
            style="width: 45px; height: 45px; border-radius: 5px"
            :src="require('@/assets/png/app_icon2.png')"
            fit="contain"></el-image>
      </div>

      <div style="width: 100%; padding-left: 5px">
        <div :class="language === 'uy' ? 'bottom-app-name-uy' : 'bottom-app-name'">{{ $t("message.appName") }}</div>
        <div :class="language === 'uy' ? 'bottom-app-introduce-uy' : 'bottom-app-introduce'">
          {{ $t("message.appIntroduce") }}
        </div>
      </div>
      <div class="open-app-button" :style="language === 'uy' ? 'font-family: UKIJTor' : 'font-family: 黑体'"
           @click="openApp()">
        {{ $t("message.openApp") }}
      </div>
      <div style="padding: 2px 10px; line-height: 20px;" @click="isShowOpenAppButton = false">
        <i class="el-icon-close"></i>
      </div>

    </div>

  </div>

</template>

<script>
import {requestPath, getCommonParams, getQuery} from '@/utils/util'
import {getSign} from '@/utils/md5'
import $ from 'jquery'
import wxapi from '@/utils/wxapi.js';

let audio;

export default {
  name: "share",
  data() {
    return {
      // 通用协议地址
      commSchemeUrl: "qabillar://qabillar.cn",
      commSchemeIosUrl: "https://open.qabillar.cn/openios/",
      // iOS 下载地址
      iOSDownloadUrl: "https://apps.apple.com/cn/app/qabillar/id1580325985",
      // Android 下载地址
      androidDownloadUrl: "https://m.pp.cn/detail.html?appid=8287197&ch_src=pp_dev&ch=default",
      //公共请求参数
      commonParams: null,
      language: "uy",
      //url参数
      urlParams: "",
      //页面文章id
      detailId: "",
      detailData: "",
      recommendList: [],
      isShowOpenAppButton: true,
      isAudioPause: true,

      //微信api初始化对象
      wxData: null,
      //微信分享对象
      wxOption: null,
      centerDialogVisible: false
    }
  },
  created() {
    this.$nextTick(() => {
      // 禁用右键
      document.oncontextmenu = new Function("event.returnValue=false");
      // 禁用选择
      document.onselectstart = new Function("event.returnValue=false");
    });
    audio = new Audio();
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      //获取公共请求参数
      this.commonParams = getCommonParams()
      //获取url参数
      this.urlParams = getQuery()
      this.language = this.urlParams.language
      this.detailId = this.urlParams.id

      this.getDetailMethod()
    },

    /**
     * 修改后端返回的html页面
     */
    updateHtml() {
      //$("dl img") 层级选择器
      $("dl img").attr("width", "100%").attr("text-align", "center")
      $("dl video").attr("width", "100%")
      // $("dl span").attr("style", "UKIJTor")
      $("dl span").attr("style", function (i, origValue) {
        return origValue + "; font-family: 'UKIJTor' !important; font-size: 13px !important; text-align: right !important;";
      });
      $("dl table").attr("style", function (i, origValue) {
        return origValue + " width: 100% !important;";
      });

    },

    //判断是否在微信打开
    isWeiXin() {
      var ua = window.navigator.userAgent.toLowerCase();
      console.log(ua);//mozilla/5.0 (iphone; cpu iphone os 9_1 like mac os x) applewebkit/601.1.46 (khtml, like gecko)version/9.0 mobile/13b143 safari/601.1
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        return true;
      } else {
        return false;
      }
    },

    openApp() {
      console.info("------>openApp")
      if (this.isWeiXin()) {
        //console.info("------>需要提示打开浏览器")
        this.$notify({
          title: this.$t("message.tips"),
          message: this.$t("message.tipsContent")
        });
      } else {
        let u = navigator.userAgent;
        let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //判断是否是 android终端
        let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //判断是否是 iOS终端
        // 首次尝试打开 App 并跳转
        if (isAndroid) {
          this.android();
        }
        if (isIOS) {
          this.ios();
        }
      }
    },

    android() {
      var _clickTime = new Date().getTime();
      window.location.href = this.commSchemeUrl;

      //启动间隔20ms运行的定时器，并检测累计消耗时间是否超过3000ms，超过则结束
      var _count = 0, intHandle;
      intHandle = setInterval(function () {
        _count++;
        var elsTime = new Date().getTime() - _clickTime;
        if (_count >= 100 || elsTime > 5000) {
          console.log(_count)
          console.log(elsTime)
          clearInterval(intHandle);
          //检查app是否打开
          if (document.hidden || document.webkitHidden) {
            // 打开了
            // console.log("---->打开了")
            window.location.href = this.commSchemeUrl;
            window.close();
          } else {
            // 没打开
            // console.log("---->没打开")
            window.location.href = this.androidDownloadUrl;
          }
        }
      }, 20);

    },

    ios() {
      window.location.href = this.commSchemeIosUrl;
    },

    /**
     * 点击其他文章
     */
    clickToLearnMoreMethod(id) {
      this.detailId = id
      this.getDetailMethod()
    },

    /**
     * 获取WAP微信分享参数
     */
    getSignPackageMethod() {
      console.info("获取WAP微信分享参数")
      let _this = this
      let url = _this.detailData.share.url;

      let params = JSON.parse(JSON.stringify(this.commonParams));
      this.$set(params, "url", encodeURIComponent(url))
      let sign = getSign(params);
      this.$set(params, "sign", sign)

      this.$axios.get(requestPath() + `passport/getSignPackage`,
          {
            params: params,
            headers: {'signature': sign}
          }
      ).then(response => {
        if (response.data.code === 0) {
          _this.wxData = response.data.data
          //微信分享
          wxapi.wxRegister(this.wxData, this.wxOption);//data是微信配置信息，option是分享要配置的内容
        } else {
          this.$message.error(response.data.msg);
        }
      }).catch(function (error) { // 请求失败处理
        console.log(error);
      });
    },

    /**
     * 获取详情
     */
    getDetailMethod() {
      let _this = this
      let params = JSON.parse(JSON.stringify(this.commonParams));

      this.$set(params, "id", this.detailId)
      // this.$set(params, "mid", this.urlParams.mid)
      this.$set(params, "language", this.urlParams.language)
      this.$set(params, "app_auth_token", '')
      let sign = getSign(params);
      this.$set(params, "sign", sign)

      this.$axios.get(requestPath() + `reference/detail`,
          {
            params: params,
            headers: {'signature': sign}
          }
      ).then(response => {
        if (response.data.code === 0) {
          this.detailData = response.data.data
          this.getRecommendListMethod()
          setTimeout(function () {
            _this.updateHtml()
          }, 0)
          this.backTop()
          this.commSchemeUrl = "qabillar://qabillar.cn/share?id=" + this.detailData.id + "&category=" + this.detailData.category
          this.commSchemeIosUrl = "https://open.qabillar.cn/openios?id=" + this.detailData.id + "&category=" + this.detailData.category

          const audio_url = this.detailData.audio_url;
          audio.src = audio_url;

          this.wxOption = {
            title: this.detailData.share.title, // 分享标题
            desc: "", // 分享描述
            link: this.detailData.share.url, // 分享链接
            imgUrl: this.detailData.share.img, // 分享图标
          }
          this.getSignPackageMethod()
        } else {
          this.$message.error(response.data.msg);
        }
      }).catch(function (error) { // 请求失败处理
        console.log(error);
      });
    },

    backTop() {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    },

    /**
     * 获取推荐列表
     */
    getRecommendListMethod() {
      let params = JSON.parse(JSON.stringify(this.commonParams));

      this.$set(params, "id", this.detailId)
      this.$set(params, "type", this.detailData.type.toString())
      this.$set(params, "page", "1")
      this.$set(params, "page_size", "10")
      this.$set(params, "language", this.urlParams.language)

      let sign = getSign(params);
      this.$set(params, "sign", sign)

      this.$axios.get(requestPath() + `reference/recommendList`,
          {
            params: params,
            headers: {'signature': sign}
          }
      ).then(response => {
        if (response.data.code === 0) {
          this.recommendList = response.data.data.list
        } else {
          this.$message.error(response.data.msg);
        }
      }).catch(function (error) { // 请求失败处理
        console.log(error);
      });
    },

    clickAudio() {
      if (this.isAudioPause) {
        audio.play();
        this.isAudioPause = false;
      } else {
        audio.pause();
        this.isAudioPause = true;
      }
    }

  },
}
</script>

<style lang="scss" src="../../styles/customElement1.scss"></style>
<style scoped>
#index {
  background-color: #f5f7f8;
}

.my-font-text {
  /*这里的UKIJTor是引入时的自定义名字*/
  font-family: 'UKIJTor';
}

.title {
  font-weight: bold;
}

.audio {
  /* 横向布局 */
  display: flex;
  flex-direction: row;
  /* 水平居中 */
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
}

.detailData-introduction {
  background-color: #f2f3f6;
  color: #606266;
  padding: 9px;
  /* 圆角 */
  border-radius: 6px;
  font-size: 13px;
}

.bottom {
  font-size: 10px;
  color: #909399;
}

.charging-tips {
  text-align: center;
}

.charging-button {
  color: #303133;
  /* 圆角 */
  border-radius: 30px;
  background-color: #fcbe96;
  padding: 2px 15px;
}

.bottom-fabulous-row {
  /* 横向布局 */
  display: flex;
  flex-direction: row;
  /* 水平居中 */
  justify-content: center;
}

.bottom-fabulous-button {
  width: 50px;
  height: 50px;
  padding: 8px;
  border: 1px solid #f16217;
  text-align: center;
  /* 圆角 */
  border-radius: 50px;
}

.bottom-list {
  /* 横向布局 */
  display: flex;
  flex-direction: row;
}

.recommend {
  padding: 15px 0;
  /* 横向布局 */
  display: flex;
  flex-direction: row;
}

.recommend-r {
  padding: 0 10px;
  font-size: 12px;
}

.bottom-app {
  text-align: left !important;
  /*height: 45px;*/
  padding: 5px 10px;
  background-color: #fff;
  position: fixed;
  left: 0;
  right: 0;
  bottom: -1px;
  z-index: 750;
  box-shadow: 12px 2px 12px 10px rgba(0, 0, 0, 0.1);
  /* 横向布局 */
  display: flex;
  flex-direction: row;
  /* 垂直居中 */
  align-items: center;
}

.bottom-app-name-uy {
  font-family: 'UKIJTor';
  text-align: center;
  font-size: 16px;
}

.bottom-app-name {
  font-family: '方正粗黑宋简体';
  font-size: 16px;
}

.bottom-app-introduce-uy {
  /* 强制文本文字内容不换行 */
  white-space: nowrap;
  font-size: 8px;
  color: #959595;
  font-family: 'ALKATIP_Basma';
  text-align: center;
}

.bottom-app-introduce {
  font-size: 8px;
  color: #959595;
  font-family: '方正姚体';
}

.open-app-button {
  background-color: #fb9147;
  color: #ffffff;
  font-size: 12px;
  /* 强制文本文字内容不换行 */
  white-space: nowrap;
  padding: 5px 10px;
  /* 圆角 */
  border-radius: 30px;
}
</style>